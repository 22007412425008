export const environment = {
  production: false,
  environment: 'beta',
  cfdistVideosUrl: 'https://videos.test.slick.plus/',
  serverUrl: 'https://testapi.slick.plus/',
  baseUrl: 'https://test.slick.plus/',
  localStorageKey: 'slickUser',
  imageMimeType:'image/jpeg',
  mediaDist:'https://media.test.slick.plus/',
  clientId: "1abff439-cc47-4d75-9970-d1ee2cf4f4ce", // 'Application (client) ID' of app registration in the Microsoft MsEntra admin center - this value is a GUI
  authority: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize", // Full directory URL, in the form of https://login.microsoftonline.com/<tenant>
  redirectUri: "https://test.slick.plus/ms-entra", // Must be the same redirectUri as what was provided in your app registration.
  hotjarCode: 5076006
};
